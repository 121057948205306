// AddAccountForm.js
import React, { useState, useEffect } from 'react';
import './AddAccountForm.css';

function AddAccountForm({ onClose, onAddAccount, accountToEdit = null, isEditMode = false, onDeleteAccount, setNotification }) {
  const [loading, setLoading] = useState(false); // Add this line with other useState declarations

  const [formData, setFormData] = useState({
    nickname: '',
    steamAccountName: '',
    steamPassword: '',
    steamIdentitySecret: '',
    steamSharedSecret: '',
    waxpeerApiKey: '',
    shadowpayApiKey: '',
    shadowpayWssToken: '',
    marketCsgoApiKey: '',
    csgoEmpireApiKey: '',
    CSGORollPassword:'',
    CSGORollEmailId:'',
    csgo500ApiKey: '',
    csgo500UserId: '',
    proxy: '',
    steamId: '', // Added steamId field
  });

  const [editableFields, setEditableFields] = useState({
    steamAccountName: !isEditMode,
    steamPassword: !isEditMode,
    steamIdentitySecret: !isEditMode,
    steamSharedSecret: !isEditMode,
    waxpeerApiKey: !isEditMode,
    shadowpayApiKey: !isEditMode,
    shadowpayWssToken: !isEditMode,
    marketCsgoApiKey: !isEditMode,
    csgoEmpireApiKey: !isEditMode,
    CSGORollPassword: !isEditMode,
    CSGORollEmailId: !isEditMode,
    csgo500ApiKey: !isEditMode,
    csgo500UserId: !isEditMode,
    proxy: !isEditMode,
    //steamId: !isEditMode, // Control for steamId editability
  });

  const steamCredentialsProvided = formData.steamAccountName && formData.steamPassword;

  // Populate form data when editing an account
  useEffect(() => {
    if (isEditMode && accountToEdit) {
      setFormData({
        nickname: accountToEdit.nickname || '',
        steamAccountName: accountToEdit.steamAccountName || '',
        steamPassword: accountToEdit.steamPassword || '',
        steamIdentitySecret: accountToEdit.steamIdentitySecret || '',
        steamSharedSecret: accountToEdit.steamSharedSecret || '',
        waxpeerApiKey: accountToEdit.waxpeerApiKey || '',
        shadowpayApiKey: accountToEdit.shadowpayApiKey || '',
        shadowpayWssToken: accountToEdit.shadowpayWssToken || '',
        marketCsgoApiKey: accountToEdit.marketCsgoApiKey || '',
        csgoEmpireApiKey: accountToEdit.csgoEmpireApiKey || '',
        CSGORollPassword: accountToEdit.CSGORollPassword || '',
        CSGORollEmailId: accountToEdit.CSGORollEmailId || '',
        csgo500ApiKey: accountToEdit.csgo500ApiKey || '',
        csgo500UserId: accountToEdit.csgo500UserId || '',
        proxy: accountToEdit.proxy || '',
        //steamId: accountToEdit.steamId || '', // Added steamId population
      });
    }
  }, [isEditMode, accountToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditField = (field) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field],
    }));
  };

  const validateProxy = (proxy) => {
    const proxyPattern = /^http:\/\/\w+:\w+@\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}:\d+$/;
    return proxyPattern.test(proxy);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Start loading state
  
    // Function to determine the value to send for each field in edit mode
    const getEditModeFieldValue = (fieldValue) => {
      return fieldValue.trim() !== '' ? fieldValue : "NotEdited";
    };
  
    // Initialize updatedFormData
    let updatedFormData = {};
  
    if (isEditMode) {
      // **Edit Mode Logic**
  
      // Always include 'nickname'
      updatedFormData.nickname = formData.nickname;
  
      // List of fields to include in edit mode (excluding 'steamId')
      const fieldsToInclude = [
        'steamAccountName',
        'steamPassword',
        'steamIdentitySecret',
        'steamSharedSecret',
        'waxpeerApiKey',
        'shadowpayApiKey',
        'shadowpayWssToken',
        'marketCsgoApiKey',
        'csgoEmpireApiKey',
        'CSGORollPassword',
        'CSGORollEmailId',
        'csgo500ApiKey',
        'csgo500UserId',
        'proxy',
      ];
  
      // Populate updatedFormData with actual values or "NotEdited" based on editability
      fieldsToInclude.forEach((field) => {
        updatedFormData[field] = editableFields[field]
          ? formData[field]
          : getEditModeFieldValue(formData[field]);
      });
  
      // **Note:** 'steamId' is intentionally excluded in edit mode
    } else {
      // **Add Mode Logic**
  
      // Populate updatedFormData with all form fields as entered
      updatedFormData = { ...formData };
      // Optionally, you can remove 'steamId' if it's not needed in add mode
      // delete updatedFormData.steamId;
    }
  
    // **Validation Logic**
  
    // Extract necessary fields for validation
    const {
      steamAccountName,
      steamPassword,
      steamIdentitySecret,
      steamSharedSecret,
      CSGORollEmailId,
      CSGORollPassword,
      csgo500ApiKey,
      csgo500UserId,
      csgoEmpireApiKey,
      proxy,
    } = updatedFormData;
  
    // Determine if proxy is required based on provided conditions
    const proxyRequired =
      (steamAccountName && steamPassword) ||
      CSGORollEmailId ||
      CSGORollPassword ||
      csgo500ApiKey ||
      csgo500UserId ||
      csgoEmpireApiKey;
  
    // **Edit Mode Validation**
    if (isEditMode) {
      // If the proxy field is editable and has been modified
      if (editableFields.proxy) {
        if (!proxy || proxy.trim() === '') {
          setNotification({
            message:
              'Proxy is required when Steam, CSGORoll, CSGO500, or CSGOEmpire details are provided.',
            type: 'error',
          });
          setLoading(false);
          return;
        }
        if (proxy && !validateProxy(proxy)) {
          console.log("The 2 / 2 ", proxy);
          setNotification({
            message: 'Proxy must be in the format http://login:pass@IP:port.',
            type: 'error',
          });
          setLoading(false);
          return;
        }
      }
    } else {
      // **Add Mode Validation**
  
      // If Steam credentials are provided, ensure all Steam fields are filled
      if (steamPassword || steamAccountName) {
        if (
          !steamAccountName ||
          !steamPassword ||
          !steamIdentitySecret ||
          !steamSharedSecret
        ) {
          setNotification({
            message:
              'If you provide Steam Account Name or Password, all Steam details including Identity Secret and Shared Secret must be provided.',
            type: 'error',
          });
          setLoading(false);
          return;
        }
      }
  
      // Check if proxy is required and validate its format
      if (proxyRequired && (!proxy || proxy.trim() === '')) {
        setNotification({
          message:
            'Proxy is required when Steam, CSGORoll, CSGO500, or CSGOEmpire details are provided.',
          type: 'error',
        });
        setLoading(false);
        return;
      }
  
      if (proxy && !validateProxy(proxy)) {
        console.log("The 1 / 2 ", proxy);
        setNotification({
          message: 'Proxy must be in the format http://login:pass@IP:port.',
          type: 'error',
        });
        setLoading(false);
        return;
      }
    }
  
    try {
      // Notify the user that the account verification is in progress
      setNotification({
        message:
          'Account verification is in progress. This may take a few moments.',
        type: 'success',
      });
  
      // Determine the API endpoint and HTTP method based on the mode
      const url = isEditMode
        ? `/api/account-details/${formData.nickname}`
        : '/api/account-details';
      const method = isEditMode ? 'PUT' : 'POST'; // Use PUT for editing, POST for adding
  
      // Make the API request with the appropriate method and data
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFormData),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (isEditMode) {
          // Update the account in the parent component
          onAddAccount(updatedFormData);
          setNotification({
            message: 'Account updated successfully.',
            type: 'success',
          });
        } else {
          // Add the new account in the parent component
          onAddAccount(updatedFormData);
          setNotification({
            message: 'Account added successfully.',
            type: 'success',
          });
        }
        onClose(); // Close the form/modal
      } else {
        // Handle server-side errors
        setNotification({
          message: `Error: ${data.message}`,
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network or other unexpected errors
      console.error('Error:', error);
      setNotification({
        message: 'An error occurred while processing the account.',
        type: 'error',
      });
    } finally {
      setLoading(false); // Reset loading state after the request completes
    }
  };
  
  

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/account-details/${accountToEdit.nickname}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok) {
        onDeleteAccount(accountToEdit.nickname);
        setNotification({ message: 'Account deleted successfully.', type: 'success' });
        onClose();

        // Wait for 2 seconds before refreshing the account list
        setTimeout(async () => {
          try {
            const refreshResponse = await fetch('/api/account-details', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            const refreshedAccounts = await refreshResponse.json();
            if (refreshResponse.ok) {
              onAddAccount(refreshedAccounts);
              setNotification({ message: 'Account details refreshed successfully.', type: 'success' });
            } else {
              setNotification({ message: `Error refreshing account details: ${refreshedAccounts.message}`, type: 'error' });
            }
          } catch (error) {
            console.error('Error refreshing account details:', error);
            setNotification({ message: 'Error refreshing account details.', type: 'error' });
          }
        }, 2000); // 2000 milliseconds = 2 seconds

      } else {
        setNotification({ message: `Error: ${data.message}`, type: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setNotification({ message: 'An error occurred while deleting the account.', type: 'error' });
    }
  };

  const { steamAccountName, steamPassword , CSGORollEmailId , CSGORollPassword ,csgo500ApiKey , csgo500UserId , csgoEmpireApiKey } = formData;
  const proxyRequired = steamAccountName && steamPassword   
  const proxyRequiredRoll = CSGORollEmailId && CSGORollPassword 
  const proxyRequired500 = csgo500ApiKey && csgo500UserId
  const proxyRequiredempire = csgoEmpireApiKey
  const isProxyRequired = proxyRequired || proxyRequiredRoll || proxyRequired500 || proxyRequiredempire;
  return (
    <div className="add-account-form-overlay">
      <div className="add-account-form">
        <h2>{isEditMode ? 'Edit Account' : 'Add New Account'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>Nickname (required)</label>
              <input
                type="text"
                name="nickname"
                value={formData.nickname}
                onChange={handleChange}
                required
                readOnly={isEditMode} // Read-only in edit mode
              />
            </div>
            <div className="form-group">
              <label>Steam Account Name</label>
              <input
                type="text"
                name="steamAccountName"
                value={formData.steamAccountName}
                onChange={handleChange}
                disabled={!editableFields.steamAccountName}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('steamAccountName')}
                >
                  {editableFields.steamAccountName ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>Steam Password</label>
              <input
                type="password"
                name="steamPassword"
                value={formData.steamPassword}
                onChange={handleChange}
                disabled={!editableFields.steamPassword}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('steamPassword')}
                >
                  {editableFields.steamPassword ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Steam Identity Secret</label>
              <input
                type="text"
                name="steamIdentitySecret"
                value={formData.steamIdentitySecret}
                onChange={handleChange}
                disabled={!editableFields.steamIdentitySecret}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('steamIdentitySecret')}
                >
                  {editableFields.steamIdentitySecret ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>Steam Shared Secret</label>
              <input
                type="text"
                name="steamSharedSecret"
                value={formData.steamSharedSecret}
                onChange={handleChange}
                disabled={!editableFields.steamSharedSecret}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('steamSharedSecret')}
                >
                  {editableFields.steamSharedSecret ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            
            <div className="form-group">
            {!isEditMode && (
                <>
                  <label>SteamID (Required if Steam details not provided)</label>
                  <input
                    type="text"
                    name="steamId"
                    value={formData.steamId}
                    onChange={handleChange}
                    disabled={steamCredentialsProvided} // Disable if Steam credentials are provided
                    required={!steamCredentialsProvided} // Required if Steam credentials are not provided
                  />
                </>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Waxpeer API Key</label>
              <input
                type="text"
                name="waxpeerApiKey"
                value={formData.waxpeerApiKey}
                onChange={handleChange}
                disabled={!editableFields.waxpeerApiKey}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('waxpeerApiKey')}
                >
                  {editableFields.waxpeerApiKey ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Shadowpay API Key</label>
              <input
                type="text"
                name="shadowpayApiKey"
                value={formData.shadowpayApiKey}
                onChange={handleChange}
                disabled={!editableFields.shadowpayApiKey}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('shadowpayApiKey')}
                >
                  {editableFields.shadowpayApiKey ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>Shadowpay WSS Token</label>
              <input
                type="text"
                name="shadowpayWssToken"
                value={formData.shadowpayWssToken}
                onChange={handleChange}
                disabled={!editableFields.shadowpayWssToken}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('shadowpayWssToken')}
                >
                  {editableFields.shadowpayWssToken ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>Market CSGO API Key</label>
              <input
                type="text"
                name="marketCsgoApiKey"
                value={formData.marketCsgoApiKey}
                onChange={handleChange}
                disabled={!editableFields.marketCsgoApiKey}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('marketCsgoApiKey')}
                >
                  {editableFields.marketCsgoApiKey ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>CSGOEmpire API Key</label>
              <input
                type="text"
                name="csgoEmpireApiKey"
                value={formData.csgoEmpireApiKey}
                onChange={handleChange}
                disabled={!editableFields.csgoEmpireApiKey}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('csgoEmpireApiKey')}
                >
                  {editableFields.csgoEmpireApiKey ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>CSGORoll Password</label>
              <input
                type="text"
                name="CSGORollPassword"
                value={formData.CSGORollPassword}
                onChange={handleChange}
                disabled={!editableFields.CSGORollPassword}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('CSGORollPassword')}
                >
                  {editableFields.CSGORollPassword ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>CSGORoll Email Id</label>
              <input
                type="text"
                name="CSGORollEmailId"
                value={formData.CSGORollEmailId}
                onChange={handleChange}
                disabled={!editableFields.CSGORollEmailId}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('CSGORollEmailId')}
                >
                  {editableFields.CSGORollEmailId ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>CSGO500 API Key</label>
              <input
                type="text"
                name="csgo500ApiKey"
                value={formData.csgo500ApiKey}
                onChange={handleChange}
                disabled={!editableFields.csgo500ApiKey}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('csgo500ApiKey')}
                >
                  {editableFields.csgo500ApiKey ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
            <div className="form-group">
              <label>CSGO500 User ID</label>
              <input
                type="text"
                name="csgo500UserId"
                value={formData.csgo500UserId}
                onChange={handleChange}
                disabled={!editableFields.csgo500UserId}
              />
              {isEditMode && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => toggleEditField('csgo500UserId')}
                >
                  {editableFields.csgo500UserId ? 'Lock' : 'Edit'}
                </button>
              )}
            </div>
          </div>

          <div className="form-group">
          <label>
              Proxy {isProxyRequired ? '(required)' : ''}
            </label>
            <input
              type="text"
              name="proxy"
              value={formData.proxy}
              onChange={handleChange}
              placeholder="http://login:pass@IP:port"
              required={isProxyRequired} // Conditionally require the proxy field
              disabled={!editableFields.proxy}
            />
            {isEditMode && (
              <button
                type="button"
                className="edit-btn"
                onClick={() => toggleEditField('proxy')}
              >
                {editableFields.proxy ? 'Lock' : 'Edit'}
              </button>
            )}
          </div>

          <div className="form-actions">
            <button type="submit" disabled={loading}>{isEditMode ? 'Save Changes' : 'Add Account'}</button> 
            {isEditMode && (
              <button
                type="button"
                className="delete-btn"
                onClick={handleDelete}
              >
                Delete Account
              </button>
            )}
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAccountForm;
