// Sell.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Homepagemodules/Navbar';
import Footer from './Homepagemodules/homepagefooter';
import './Sell.css'; // Ensure you have a corresponding CSS file for Sell
import market from './pictures/market.png';
import csgoempire from './pictures/csgoempire.png';
import waxpeer from './pictures/waxpeer.svg';
import csgo500 from './pictures/csgo500.gif';
import shadowpay from './pictures/shadowpay.svg'; // Ensure this is the correct image
import csgoorollico from './pictures/roll2.png'; // Ensure this is the correct image
import SearchWithSettings from './components/SearchWithSettings'; // Import the new component
import SettingsMenu from './components/SettingsMenu'; // Import the new component
import SaleOverview from './components/SaleOverview/SaleOverview'; // Import the SaleOverview component
import './components/loadingscreen.css';
function Sell() {
    // State Variables
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [globalToggles, setGlobalToggles] = useState({});
    const [undercuttingGlobalToggles, setUndercuttingGlobalToggles] = useState({}); // New state for undercutting
    const [accountToggles, setAccountToggles] = useState({});
    const [undercuttingAccountToggles, setUndercuttingAccountToggles] = useState({}); // New state for undercutting
    const [accountNicknames, setAccountNicknames] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [accountFunctionalities, setAccountFunctionalities] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);

    // New State Variables for Undercutting Section
    const [undercuttingSearchInput, setUndercuttingSearchInput] = useState('');
    const [undercuttingShowDropdown, setUndercuttingShowDropdown] = useState(false);
    const [filteredUndercuttingAccounts, setFilteredUndercuttingAccounts] = useState([]);

    // State Variables for Settings Menu
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [settingsContext, setSettingsContext] = useState('global'); // 'global' or 'undercutting'
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [modifiers, setModifiers] = useState({
        market_csgo: '',
        csgoempire: '',
        CSGORoll :'',
        waxpeer: '',
        csgo500: '',
        shadowpay: '',
    });

    const navigate = useNavigate();
    const hasFetched = useRef(false);
    const searchRef = useRef(null);
    const undercuttingSearchRef = useRef(null); // Ref for undercutting search
    const settingsMenuRef = useRef(null); // Ref for settings menu
    const [accountPurchaseData, setAccountPurchaseData] = useState({
        Today: [],
        "Last 5 Days": [],
        "Last 10 Days": [],
        "Last 30 Days": [],
    });

    const [purchaseData, setPurchaseData] = useState({
        Today: [],
        "Last 5 Days": [],
        "Last 10 Days": [],
        "Last 30 Days": [],
    });
    // Mapping between toggle names and functionalities
    const toggleFunctionalityMap = {
        marketselling_functionality: 'marketselling_functionality',
        csgoempireselling_functionality: 'csgoempireselling_functionality',
        CSGORollselling_functionlaity: 'CSGORollselling_functionlaity',
        waxpeerselling_functionality: 'waxpeerselling_functionality',
        selling500_functionality: 'selling500_functionality',
        shadowpayselling_functionality: 'shadowpayselling_functionality',
        // Add other selling mappings as needed
    };

    // Mapping for undercutting functionalities
    const undercuttingFunctionalityMap = {
        marketundercutting_functionality: 'marketundercutting_functionality',
        csgoempireundercutting_functionality: 'csgoempireundercutting_functionality',
        CSGORollundercutting_functionlaity: 'CSGORollundercutting_functionlaity',
        waxpeerundercutting_functionality: 'waxpeerundercutting_functionality',
        undercutting500_functionality: 'undercutting500_functionality',
        shadowpayundercutting_functionality: 'shadowpayundercutting_functionality',
        // Add other undercutting mappings as needed
    };

    // Reverse mapping for functionalities to toggle names
    const functionalityToggleReverseMap = Object.fromEntries(
        Object.entries(toggleFunctionalityMap).map(([k, v]) => [v, k])
    );

    const undercuttingFunctionalityToggleReverseMap = Object.fromEntries(
        Object.entries(undercuttingFunctionalityMap).map(([k, v]) => [v, k])
    );

    // Fetch Authentication and Initial Data
    useEffect(() => {
        const checkAuthAndFetchData = async () => {
            try {
                if (hasFetched.current) return;
                hasFetched.current = true;

                // Retrieve the authentication token
                const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

                // Check if the user is authenticated
                const authResponse = await fetch('/api/auth/isAuthenticated', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Include the token
                    },
                    credentials: 'include', // Include cookies if needed
                });

                if (authResponse.ok) {
                    setAuthenticated(true);

                    // Fetch global toggles
                    const togglesResponse = await fetch('/api/toggles', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        credentials: 'include',
                    });

                    if (togglesResponse.ok) {
                        const togglesData = await togglesResponse.json();
                        setGlobalToggles(togglesData.globaltoggles);

                        // Assuming undercutting toggles are part of globaltoggles
                        setUndercuttingGlobalToggles(togglesData.globaltoggles || {});
                    } else {
                        const errorData = await togglesResponse.json();
                        console.error(errorData.message || 'Failed to fetch toggles');
                    }

                    // Fetch account nicknames
                    const accountsResponse = await fetch('/api/users/account-nicknames', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        credentials: 'include',
                    });

                    if (accountsResponse.ok) {
                        const accountsData = await accountsResponse.json();
                        setAccountNicknames(accountsData.accountNicknames);
                        setFilteredAccounts(accountsData.accountNicknames); // Initialize filteredAccounts
                        setFilteredUndercuttingAccounts(accountsData.accountNicknames); // Initialize undercutting filteredAccounts
                    } else {
                        const errorData = await accountsResponse.json();
                        console.error(errorData.message || 'Failed to fetch account nicknames');
                    }
                    // **Add this line to fetch global purchase data**
                    await fetchPurchaseData('global');
                } else {
                    // If not authenticated, redirect to home page
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
                navigate('/');
            } finally {
                setLoading(false); // Set loading to false after operations
            }
        };

        checkAuthAndFetchData();
    }, [navigate]);

    // Handle Clicks Outside the Search Dropdowns and Settings Menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
            if (undercuttingSearchRef.current && !undercuttingSearchRef.current.contains(event.target)) {
                setUndercuttingShowDropdown(false);
            }
            if (settingsMenuRef.current && !settingsMenuRef.current.contains(event.target)) {
                setSettingsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const fetchPurchaseData = async (nickname = 'global') => {
        try {
            const token = localStorage.getItem('authToken');
            const endpoint = nickname === 'global' ? '/api/solditems/global' : `/api/solditems/${encodeURIComponent(nickname)}`;

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch purchases');
            }

            const data = await response.json();
            
            if (nickname === 'global') {
                setPurchaseData(data);
            } else {
                setAccountPurchaseData(data);
            }
        } catch (error) {
            console.error('Error fetching purchases:', error);
        }
    };
    // Handler: Toggle Global Toggles
    const handleGlobalToggleChange = async (toggleName) => {
        try {
            // Create a new toggles object with the updated toggle
            const updatedToggles = {
                ...globalToggles,
                [toggleName]: !globalToggles[toggleName],
            };

            // Update the state optimistically
            setGlobalToggles(updatedToggles);

            // Retrieve the authentication token
            const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

            // Send the update to the backend
            const response = await fetch('/api/toggles', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    globaltoggles: {
                        [toggleName]: updatedToggles[toggleName],
                    },
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update toggle');
            }

            // Optionally, you can provide user feedback here (e.g., toast notification)
        } catch (error) {
            console.error('Error updating toggle:', error);

            // Revert the toggle in case of an error
            setGlobalToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: !prevToggles[toggleName],
            }));

            // Optionally, display an error message to the user
        }
    };

    // Handler: Toggle Undercutting Global Toggles
    const handleUndercuttingGlobalToggleChange = async (toggleName) => {
        try {
            // Create a new undercutting toggles object with the updated toggle
            const updatedToggles = {
                ...undercuttingGlobalToggles,
                [toggleName]: !undercuttingGlobalToggles[toggleName],
            };

            // Update the state optimistically
            setUndercuttingGlobalToggles(updatedToggles);

            // Retrieve the authentication token
            const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

            // Send the update to the backend
            const response = await fetch('/api/toggles', { // Ensure this endpoint handles undercutting toggles
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    globaltoggles: {
                        [toggleName]: updatedToggles[toggleName],
                    },
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update undercutting toggle');
            }

            // Optionally, provide user feedback here
        } catch (error) {
            console.error('Error updating undercutting toggle:', error);

            // Revert the toggle in case of an error
            setUndercuttingGlobalToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: !prevToggles[toggleName],
            }));

            // Optionally, display an error message to the user
        }
    };

    // Handler: Toggle Account-Specific Functionalities
    const handleAccountToggleChange = async (toggleName) => {
        try {
            // Map toggle name to functionality
            const functionality = toggleFunctionalityMap[toggleName];
            if (!functionality) {
                console.warn(`No functionality mapped for toggle: ${toggleName}`);
                return;
            }

            // Determine the new state: true or false
            const newState = !accountToggles[toggleName];

            // Ensure accountFunctionalities is an array before updating
            const currentFunctionalities = Array.isArray(accountFunctionalities) ? accountFunctionalities : [];

            // Optimistically update the accountToggles
            setAccountToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: newState,
            }));

            // Update the accountFunctionalities state optimistically
            let updatedFunctionalities;
            if (newState) {
                updatedFunctionalities = [...currentFunctionalities, functionality];
            } else {
                updatedFunctionalities = currentFunctionalities.filter((func) => func !== functionality);
            }
            console.log(updatedFunctionalities);
            setAccountFunctionalities(updatedFunctionalities);

            // Retrieve the authentication token
            const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

            // Send the specific functionality and state to the backend
            const response = await fetch(`/api/accounts/${encodeURIComponent(searchInput)}/functionality`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ functionality, state: newState }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update functionality.');
            }

            console.log('Functionality updated successfully.');
        } catch (error) {
            console.error('Error updating functionality:', error);
            // Optionally, revert changes by refetching functionalities or show error message
            fetchAccountFunctionalities();
        }
    };

    // Handler: Toggle Account-Specific Undercutting Functionalities
    const handleAccountUndercuttingToggleChange = async (toggleName) => {
        try {
            // Map toggle name to undercutting functionality
            const functionality = undercuttingFunctionalityMap[toggleName];
            if (!functionality) {
                console.warn(`No undercutting functionality mapped for toggle: ${toggleName}`);
                return;
            }

            // Determine the new state: true or false
            const newState = !undercuttingAccountToggles[toggleName];

            // Ensure accountFunctionalities is an array before updating
            const currentFunctionalities = Array.isArray(accountFunctionalities) ? accountFunctionalities : [];

            // Optimistically update the undercuttingAccountToggles
            setUndercuttingAccountToggles((prevToggles) => ({
                ...prevToggles,
                [toggleName]: newState,
            }));

            // Update the accountFunctionalities state optimistically
            let updatedFunctionalities;
            if (newState) {
                updatedFunctionalities = [...currentFunctionalities, functionality];
            } else {
                updatedFunctionalities = currentFunctionalities.filter((func) => func !== functionality);
            }
            console.log(updatedFunctionalities);
            setAccountFunctionalities(updatedFunctionalities);

            // Retrieve the authentication token
            const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

            // Send the specific undercutting functionality and state to the backend
            const response = await fetch(`/api/accounts/${encodeURIComponent(undercuttingSearchInput || searchInput)}/functionality`, { // Ensure this endpoint handles undercutting
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ functionality, state: newState }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update undercutting functionality.');
            }

            console.log('Undercutting functionality updated successfully.');
        } catch (error) {
            console.error('Error updating undercutting functionality:', error);
            // Optionally, revert changes by refetching functionalities or show error message
            fetchAccountFunctionalities();
        }
    };

// Handler: Search Input Change for Selling
const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchInput(query);
    if (!showDropdown) setShowDropdown(true);

    // Filter accounts based on search input
    if (query.trim() === '') {
        setFilteredAccounts(accountNicknames); // Show all accounts if search input is empty
    } else {
        const filtered = accountNicknames.filter((nickname) =>
            nickname.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredAccounts(filtered);
    }
};

// Handler: Search Input Change for Undercutting
const handleUndercuttingSearchChange = (e) => {
    const query = e.target.value;
    setUndercuttingSearchInput(query);
    if (!undercuttingShowDropdown) setUndercuttingShowDropdown(true);

    // Filter accounts based on search input
    if (query.trim() === '') {
        setFilteredUndercuttingAccounts(accountNicknames); // Show all accounts if search input is empty
    } else {
        const filtered = accountNicknames.filter((nickname) =>
            nickname.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredUndercuttingAccounts(filtered);
    }
};

// Handler: Select an Account from Selling Dropdown
const handleAccountSelect = async (selectedNickname) => {
    try {
        console.log('Selected Account (Selling):', selectedNickname);
        await fetchPurchaseData(selectedNickname);
        setSearchInput(selectedNickname);
        setShowDropdown(false);
        setSelectedAccount(selectedNickname); // Track the selected account

        // Retrieve the authentication token
        const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

        // Fetch the selected account's functionalities
        const response = await fetch(`/api/accounts/${encodeURIComponent(selectedNickname)}/functionality`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch account functionalities.');
        }

        const data = await response.json();
        //console.log('Account Functionalities (Selling):', data); // Log the full response to debug

        // Initialize accountToggles based on fetched functionalities
        let updatedAccountToggles = {};
        let updatedUndercuttingAccountToggles = {};

        // Set all toggles to false initially
        Object.keys(toggleFunctionalityMap).forEach((toggle) => {
            updatedAccountToggles[toggle] = false;
        });

        Object.keys(undercuttingFunctionalityMap).forEach((toggle) => {
            updatedUndercuttingAccountToggles[toggle] = false;
        });

        // Iterate over the data.functionalities object, which contains the selling functionalities
        Object.keys(data.functionalities).forEach((funcKey) => {
            const toggleName = functionalityToggleReverseMap[funcKey];
            if (toggleName) {
                updatedAccountToggles[toggleName] = data.functionalities[funcKey]; // Use the actual value from data.functionalities
            }
        });

        // Iterate over the data.functionalities object, which contains the undercutting functionalities
        if (data.functionalities) { // Assuming undercutting functionalities are in a separate key
            Object.keys(data.functionalities).forEach((funcKey) => {
                const toggleName = undercuttingFunctionalityToggleReverseMap[funcKey];
                if (toggleName) {
                    updatedUndercuttingAccountToggles[toggleName] = data.functionalities[funcKey];
                }
            });
        }

        //console.log('Updated Account Toggles:', updatedUndercuttingAccountToggles);
        setAccountToggles(updatedAccountToggles); // Update the state to reflect selling toggle changes
        setUndercuttingAccountToggles(updatedUndercuttingAccountToggles); // Update the state to reflect undercutting toggle changes
    } catch (error) {
        console.error('Error selecting account (Selling):', error);
    }
};

// Handler: Select an Account from Undercutting Dropdown
const handleUndercuttingAccountSelect = async (selectedNickname) => {
    try {
        console.log('Selected Account (Undercutting):', selectedNickname);
        setUndercuttingSearchInput(selectedNickname);
        setUndercuttingShowDropdown(false);
        setSelectedAccount(selectedNickname); // Track the selected account

        // Retrieve the authentication token
        const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

        // Fetch the selected account's functionalities
        const response = await fetch(`/api/accounts/${encodeURIComponent(selectedNickname)}/functionality`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch account functionalities.');
        }

        const data = await response.json();
        //console.log('Account Functionalities (Undercutting):', data); // Log the full response to debug

        // Initialize undercuttingAccountToggles based on fetched functionalities
        let updatedUndercuttingAccountToggles = {};

        // Set all undercutting toggles to false initially
        Object.keys(undercuttingFunctionalityMap).forEach((toggle) => {
            updatedUndercuttingAccountToggles[toggle] = false;
        });

        // Iterate over the data.functionalities object, which contains the undercutting functionalities
        if (data.functionalities) { // Assuming undercutting functionalities are in a separate key
            Object.keys(data.functionalities).forEach((funcKey) => {
                const toggleName = undercuttingFunctionalityToggleReverseMap[funcKey];
                if (toggleName) {
                    updatedUndercuttingAccountToggles[toggleName] = data.functionalities[funcKey];
                }
            });
        }
        //console.log("Selected 2 ",updatedUndercuttingAccountToggles )
        setUndercuttingAccountToggles(updatedUndercuttingAccountToggles); // Update undercutting toggles
    } catch (error) {
        console.error('Error selecting account (Undercutting):', error);
    }
};

// Helper Function: Fetch Account Functionalities (Used for Reverting State on Error)
const fetchAccountFunctionalities = async () => {
    try {
        // Retrieve the authentication token
        const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

        const response = await fetch(`/api/accounts/${encodeURIComponent(searchInput)}/functionality`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch account functionalities.');
        }

        const data = await response.json();
        setAccountFunctionalities(data.functionalities);
        // Initialize accountToggles based on fetched functionalities
        let updatedAccountToggles = {};
        let updatedUndercuttingAccountToggles = {};

        // Set all toggles to false initially
        Object.keys(toggleFunctionalityMap).forEach((toggle) => {
            updatedAccountToggles[toggle] = false;
        });

        Object.keys(undercuttingFunctionalityMap).forEach((toggle) => {
            updatedUndercuttingAccountToggles[toggle] = false;
        });

        // Iterate over the data.functionalities object, which contains the selling functionalities
        Object.keys(data.functionalities).forEach((funcKey) => {
            const toggleName = functionalityToggleReverseMap[funcKey];
            if (toggleName) {
                updatedAccountToggles[toggleName] = data.functionalities[funcKey];
            }
        });

        // Iterate over the data.functionalities object, which contains the undercutting functionalities
        if (data.functionalities) { // Assuming undercutting functionalities are in a separate key
            Object.keys(data.functionalities).forEach((funcKey) => {
                const toggleName = undercuttingFunctionalityToggleReverseMap[funcKey];
                if (toggleName) {
                    updatedUndercuttingAccountToggles[toggleName] = data.functionalities[funcKey];
                }
            });
        }
        console.log(updatedUndercuttingAccountToggles)
        setAccountToggles(updatedAccountToggles); // Update selling toggles
        setUndercuttingAccountToggles(updatedUndercuttingAccountToggles); // Update undercutting toggles
    } catch (error) {
        console.error('Error fetching account functionalities:', error);
        // Optionally, handle the error (e.g., display a message to the user)
    }
};

// Handler: Clear Account Selection for Selling
const clearAccountSelection = () => {
    setSearchInput('');
    setAccountFunctionalities([]);
    setAccountToggles({});
    setUndercuttingAccountToggles({});
    setSelectedAccount(null);
    fetchPurchaseData('global');
};

// Handler: Clear Account Selection for Undercutting
const clearUndercuttingAccountSelection = () => {
    setUndercuttingSearchInput('');
    setAccountFunctionalities([]);
    setAccountToggles({});
    setUndercuttingAccountToggles({});
    setSelectedAccount(null);
};
    // Handler: Open Settings Menu
    const openSettingsMenu = (context) => {
        setSettingsContext(context);
        setSettingsMenuOpen(true);
    };

    // Handler: Close Settings Menu (Optional: can also be handled by clicking outside)
    const closeSettingsMenu = () => {
        setSettingsMenuOpen(false);
    };

    // Handler: Modifier Change
    const handleModifierChange = (marketKey, value) => {
        setModifiers((prevModifiers) => ({
            ...prevModifiers,
            [marketKey]: value,
        }));
    };

    // Handler: Save Modifiers
    const handleSaveModifiers = async () => {
        try {
            // Retrieve the authentication token
            const token = localStorage.getItem('authToken'); // Adjust based on your auth implementation

            // Determine the target: 'global' or 'nickname'
            const target = selectedAccount ? encodeURIComponent(selectedAccount) : 'global';

            // Map the modifiers based on the context
            let mappedModifiers = {};

            if (settingsContext === 'global') {
                // Global Settings are for Purchase Modifiers
                // Map 'market_csgo' to 'marketpurchase', etc.
                mappedModifiers = {
                    marketsale: modifiers.market_csgo,
                    csgoempiresale: modifiers.csgoempire,
                    CSGORollpurchase:modifiers.CSGORoll,
                    waxpeersale: modifiers.waxpeer,
                    csgo500sale: modifiers.csgo500,
                    shadowpaysale: modifiers.shadowpay,
                };
            } else if (settingsContext === 'undercutting') {
                // Undercutting Settings are for Undercut Modifiers
                // Map 'market_csgo' to 'marketundercut', etc.
                mappedModifiers = {
                    marketundercut: modifiers.market_csgo,
                    csgoempireundercut: modifiers.csgoempire,
                    CSGORollundercut: modifiers.CSGORoll,
                    waxpeerundercut: modifiers.waxpeer,
                    csgo500undercut: modifiers.csgo500,
                    shadowpayundercut: modifiers.shadowpay,
                };
            }

            // Remove undefined or null values
            Object.keys(mappedModifiers).forEach((key) => {
                if (mappedModifiers[key] === '' || mappedModifiers[key] === null) {
                    delete mappedModifiers[key];
                }
            });

            if (Object.keys(mappedModifiers).length === 0) {
                throw new Error('No modifiers to save.');
            }

            // Send the modifiers to the backend
            const response = await fetch(`/api/modifiers/${target}`, { // Adjust the endpoint as needed
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify(mappedModifiers),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to save modifiers');
            }

            // Optionally, provide user feedback here (e.g., toast notification)
            console.log('Modifiers saved successfully');
            setSettingsMenuOpen(false); // Close the menu after saving

            // Optionally, refresh the toggles to reflect the saved changes
            if (target === 'global') {
                // Refresh global toggles
                const togglesResponse = await fetch('/api/toggles', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include',
                });

                if (togglesResponse.ok) {
                    const togglesData = await togglesResponse.json();
                    setGlobalToggles(togglesData.globaltoggles || {});
                    setUndercuttingGlobalToggles(togglesData.globaltoggles || {});
                }
            } else {
                // Refresh account-specific toggles
                const responseFunc = await fetch(`/api/accounts/${target}/functionality`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include',
                });

                if (responseFunc.ok) {
                    const data = await responseFunc.json();

                    // Initialize accountToggles based on fetched functionalities
                    let updatedAccountToggles = {};
                    let updatedUndercuttingAccountToggles = {};

                    // Set all toggles to false initially
                    Object.keys(toggleFunctionalityMap).forEach((toggle) => {
                        updatedAccountToggles[toggle] = false;
                    });

                    Object.keys(undercuttingFunctionalityMap).forEach((toggle) => {
                        updatedUndercuttingAccountToggles[toggle] = false;
                    });

                    // Iterate over the data.functionalities object, which contains the selling functionalities
                    Object.keys(data.functionalities).forEach((funcKey) => {
                        const toggleName = functionalityToggleReverseMap[funcKey];
                        if (toggleName) {
                            updatedAccountToggles[toggleName] = data.functionalities[funcKey];
                        }
                    });

                    // Iterate over the data.functionalities object, which contains the undercutting functionalities
                    if (data.functionalities) { // Assuming undercutting functionalities are in a separate key
                        Object.keys(data.functionalities).forEach((funcKey) => {
                            const toggleName = undercuttingFunctionalityToggleReverseMap[funcKey];
                            if (toggleName) {
                                updatedUndercuttingAccountToggles[toggleName] = data.functionalities[funcKey];
                            }
                        });
                    }

                    setAccountToggles(updatedAccountToggles); // Update selling toggles
                    setUndercuttingAccountToggles(updatedUndercuttingAccountToggles); // Update undercutting toggles
                }
            }
        } catch (error) {
            console.error('Error saving modifiers:', error);
            // Optionally, display an error message to the user
        }
    };

    // Conditional Rendering: Loading and Authentication
    if (loading) {
        return (
          <div className="loading-screen">
            <div className="loading-spinner"></div>
          </div>
        );
      }

    if (!authenticated) {
        return null;
    }



    return (
        <div className="sell-page">
            <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />
            <div className="sell-content">
                {/* First Sale Overview Section */}
                <SaleOverview
                    title="Sale Overview"
                    salesData={purchaseData}
                    selectedNickname="Global" // Passing 'Global' as the account nickname
                />

                {/* Undercutting Section */}
                <div className="undercutting-section">
                    <div className="undercutting-header">
                        <h2 className="small-heading">Undercutting Settings</h2>
                        <div className="undercutting-search-and-account" ref={undercuttingSearchRef}>
                            {/* Clear Button */}
                            {undercuttingSearchInput && (
                                <button
                                    className="clear-undercutting-selection-button"
                                    onClick={clearUndercuttingAccountSelection}
                                >
                                    Clear Undercutting Account Selection
                                </button>
                            )}
                            {/* Undercutting Search Bar with Settings Cog */}
                            <SearchWithSettings
                                value={undercuttingSearchInput}
                                onChange={handleUndercuttingSearchChange}
                                onFocus={() => setUndercuttingShowDropdown(true)}
                                placeholder="Search Accounts for Undercutting..."
                                showDropdown={undercuttingShowDropdown}
                                ariaLabel="Search Accounts for Undercutting"
                                onSettingsClick={() => openSettingsMenu('undercutting')} // Handle settings cog click
                            >
                                {filteredUndercuttingAccounts.length > 0 ? (
                                    filteredUndercuttingAccounts.map((nickname, index) => (
                                        <div
                                            key={index}
                                            className="search-item"
                                            onClick={() => handleUndercuttingAccountSelect(nickname)}
                                            role="option"
                                            tabIndex="0"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleUndercuttingAccountSelect(nickname);
                                                }
                                            }}
                                        >
                                            {nickname}
                                        </div>
                                    ))
                                ) : (
                                    <div className="search-item">No Accounts Found</div>
                                )}
                            </SearchWithSettings>
                        </div>
                    </div>

                    <div className="toggle-options">
                        {/* Define a list of undercutting toggles with their corresponding names and icons */}
                        {[
                            { name: 'marketundercutting_functionality', label: 'Market.CSGO', icon: market },
                            { name: 'csgoempireundercutting_functionality', label: 'CSGOEmpire', icon: csgoempire },
                            { name: 'CSGORollundercutting_functionlaity', label: 'CSGORoll', icon: csgoorollico },
                            { name: 'waxpeerundercutting_functionality', label: 'Waxpeer', icon: waxpeer },
                            { name: 'undercutting500_functionality', label: 'CSGO500', icon: csgo500 },
                            { name: 'shadowpayundercutting_functionality', label: 'ShadowPay', icon: shadowpay },
                            // Add other undercutting toggles as needed
                        ].map((toggle) => (
                            <div className="toggle-box" key={toggle.name}>
                                <img
                                    src={toggle.icon}
                                    alt={`${toggle.label} Selling`}
                                    className={`icon ${
                                        toggle.label === 'CSGORoll' ? 'csgoroll-icon' :
                                        toggle.label === 'CSGOEmpire' ? 'csgoempire-icon' : ''
                                    }`}
                                />
                                {toggle.label}
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={
                                            undercuttingSearchInput
                                                ? undercuttingAccountToggles[toggle.name] || false
                                                : undercuttingGlobalToggles[toggle.name] || false
                                        }
                                        onChange={() => {
                                            if (undercuttingSearchInput) {
                                                handleAccountUndercuttingToggleChange(toggle.name);
                                            } else {
                                                handleUndercuttingGlobalToggleChange(toggle.name);
                                            }
                                        }}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Toggle Section */}
                <div className="toggle-section">
                    <div className="toggle-header">
                    <h2 className="small-heading">
                        {selectedAccount && selectedAccount !== 'No Account Selected' ? `${selectedAccount} Toggles` : 'Global Toggles'}
                    </h2>
                        {/* Optional: Button to clear account selection */}
                        {searchInput && (
                            <button
                                className="clear-selection-button"
                                onClick={clearAccountSelection}
                            >
                                Clear Account Selection
                            </button>
                        )}
                        {/* Search and Account Search Container */}
                        <div className="search-and-account" ref={searchRef}>
                            {/* Global Search Bar with Settings Cog */}
                            <SearchWithSettings
                                value={searchInput}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(true)}
                                placeholder="Search Accounts..."
                                showDropdown={showDropdown}
                                ariaLabel="Search Accounts"
                                onSettingsClick={() => openSettingsMenu('global')} // Handle settings cog click
                            >
                                {filteredAccounts.length > 0 ? (
                                    filteredAccounts.map((nickname, index) => (
                                        <div
                                            key={index}
                                            className="search-item"
                                            onClick={() => handleAccountSelect(nickname)}
                                            role="option"
                                            tabIndex="0"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleAccountSelect(nickname);
                                                }
                                            }}
                                        >
                                            {nickname}
                                        </div>
                                    ))
                                ) : (
                                    <div className="search-item">No Accounts Found</div>
                                )}
                            </SearchWithSettings>
                        </div>
                    </div>

                    <div className="toggle-options">
                    {[  
                        { name: 'marketselling_functionality', label: 'Market.CSGO', icon: market },
                        { name: 'csgoempireselling_functionality', label: 'CSGOEmpire', icon: csgoempire },
                        { name: 'CSGORollselling_functionlaity', label: 'CSGORoll', icon: csgoorollico },
                        { name: 'waxpeerselling_functionality', label: 'Waxpeer', icon: waxpeer },
                        { name: 'selling500_functionality', label: 'CSGO500', icon: csgo500 },
                        { name: 'shadowpayselling_functionality', label: 'ShadowPay', icon: shadowpay },
                    ].map((toggle) => (
                        <div className="toggle-box" key={toggle.name}>
                            <img
                                src={toggle.icon}
                                alt={`${toggle.label} Selling`}
                                className={`icon ${
                                    toggle.label === 'CSGORoll' ? 'csgoroll-icon' :
                                    toggle.label === 'CSGOEmpire' ? 'csgoempire-icon' : ''
                                }`}
                            />
                            {toggle.label}
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={
                                        searchInput
                                            ? accountToggles[toggle.name] || false
                                            : globalToggles[toggle.name] || false
                                    }
                                    onChange={() => {
                                        if (searchInput) {
                                            handleAccountToggleChange(toggle.name);
                                        } else {
                                            handleGlobalToggleChange(toggle.name);
                                        }
                                    }}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                    ))}
                </div>


                    {/* Additional Sale Overview Section */}
                    <SaleOverview
                        title="Additional Sale Overview"
                        salesData={accountPurchaseData}
                        selectedNickname={selectedAccount ? selectedAccount : 'No Account Selected'}
                        />
                </div>

                {/* Settings Menu */}
                {settingsMenuOpen && (
                    <div className="settings-menu-container" ref={settingsMenuRef}>
                        <SettingsMenu
                            context={settingsContext}
                            selectedAccount={selectedAccount}
                            modifiers={modifiers}
                            onModifierChange={handleModifierChange}
                        />
                        <div className="settings-menu-actions">
                            <button
                                className="save-modifiers-button"
                                onClick={handleSaveModifiers}
                            >
                                Save
                            </button>
                            <button
                                className="cancel-modifiers-button"
                                onClick={closeSettingsMenu}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );

    
}
export default Sell;
