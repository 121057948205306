// SettingsMenu.js
import React from 'react';
import './SettingsMenu.css'; // Create this CSS file accordingly

function SettingsMenu({ context, selectedAccount, modifiers, onModifierChange }) {
    const markets = [
        { name: 'Market.CSGO', key: 'market_csgo' },
        { name: 'CSGOEmpire', key: 'csgoempire' },
        { name: 'CSGORoll', key: 'CSGORoll' },
        { name: 'Waxpeer', key: 'waxpeer' },
        { name: 'CSGO500', key: 'csgo500' },
        { name: 'ShadowPay', key: 'shadowpay' },
    ];

    return (
        <div className="settings-menu">
            <h3>{context === 'undercutting' ? 'Undercutting Settings' : 'Global Settings'}</h3>
            <div className="markets-container">
                {markets.map((market) => (
                    <div className="market-item" key={market.key}>
                        <span className="market-name">{market.name}</span>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            value={modifiers[market.key] || ''}
                            onChange={(e) => onModifierChange(market.key, e.target.value)}
                            placeholder="Modifier (%)"
                            aria-label={`Modifier for ${market.name}`}
                        />
                    </div>
                ))}
            </div>
            {selectedAccount ? (
                <p className="selected-account">Account: {selectedAccount}</p>
            ) : (
                <p className="global-tag">Global Settings</p>
            )}
        </div>
    );
}

export default SettingsMenu;
