// src/Homepagemodules/Navbar.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../pictures/logo.png';
import usa from '../pictures/USA.png';
import china from '../pictures/china.png';
import russia from '../pictures/russia.png';
import './Navbar.css';

function Navbar({ onButtonClick, authenticated, setAuthenticated }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [balance, setBalance] = useState(0.0); // State to hold the balance value
  const navigate = useNavigate();

  // Function to fetch the main balance from the API
  const fetchBalance = async () => {
    try {
      const response = await fetch('/api/balances/main/balance', {
        method: 'GET',
        credentials: 'include', // Ensures cookies are sent with the request
      });

      if (response.ok) {
        const data = await response.json();
        setBalance(data.balance); // Update the balance state with the fetched value
      } else {
        console.error('Failed to fetch balance');
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  // Use `useEffect` to fetch the balance when authenticated changes to true
  useEffect(() => {
    if (authenticated) {
      fetchBalance(); // Fetch balance initially
      const interval = setInterval(fetchBalance, 10000); // Fetch balance every 10 seconds

      return () => clearInterval(interval); // Cleanup interval on component unmount or when authenticated changes
    }
  }, [authenticated]); // Dependency array includes 'authenticated'

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setAuthenticated(false);
        setBalance(0.0); // Reset balance on logout
        navigate('/');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleLogoClick = () => {
    navigate('/'); // Redirect to homepage on logo click
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <nav className="navbar">
      <button className="logo-button" onClick={handleLogoClick}>
        <img src={logo} alt="Logo" className="logo" />
      </button>
      <ul className="nav-left">
        <li>
          <button className="nav-button" onClick={() => handleNavigate('/dashboard')}>
            Dashboard
          </button>
        </li>
        <li>
          <button className="nav-button" onClick={() => handleNavigate('/accounts')}>
            Accounts
          </button>
        </li>
        <li>
          <button className="nav-button" onClick={() => handleNavigate('/buy')}>
            Buy
          </button>
        </li>
        <li>
          <button className="nav-button" onClick={() => handleNavigate('/sell')}>
            Sell
          </button>
        </li>
        <li>
          <button className="nav-button" onClick={() => handleNavigate('/profits')}>
            Profits
          </button>
        </li>
      </ul>
      <ul className="nav-right">
        <li className="dropdown">
          <button className="dropbtn">
            <img src={usa} alt="EN" className="flag-icon" /> EN
          </button>
          <div className="dropdown-content">
            <a href="#"><img src={usa} alt="EN" className="flag-icon" /> English</a>
            <a href="#"><img src={china} alt="CN" className="flag-icon" /> Chinese</a>
            <a href="#"><img src={russia} alt="RU" className="flag-icon" /> Russian</a>
          </div>
        </li>

        {/* Conditionally render the Balance Box only if authenticated */}
        {authenticated && (
          <li className="balance-box">
            <div className="balance-container">
              <span className="wallet-icon">💼</span>
              <span className="balance-amount">${balance.toFixed(2)}</span> {/* Display the balance */}
              <button className="add-button">+</button>
            </div>
          </li>
        )}

        {authenticated ? (
          <li className="auth-dropdown">
            <button className="auth-button" onClick={toggleDropdown}>
              Welcome Back <span className="arrow">&#9662;</span>
            </button>
            <div className={`auth-dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <a href="/dashboard" className="dropdown-item">
                Dashboard
              </a>
              {/* New Transactions Link */}
              <a href="/transactions" className="dropdown-item">
                Transactions
              </a>
              <button className="dropdown-item logout-button" onClick={handleLogout}>
                Log Out
              </button>
            </div>
          </li>
        ) : (
          <li>
            <button className="steam-button" onClick={onButtonClick}>
              Log In or Sign Up For Greatness
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
