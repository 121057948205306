import React, { useState, useEffect, useRef, useCallback } from 'react';
import './SignupLoginSetup.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import steam from '../pictures/steam.png';
import google from '../pictures/google.png';

const SignupLoginSetup = ({ closeForm }) => {
  const [activeTab, setActiveTab] = useState('signup');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [fade, setFade] = useState(false);

  const modalRef = useRef(null);
  const navigate = useNavigate();  // Initialize useNavigate

  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeForm();
    }
  }, [closeForm]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (success || error) {
      setFade(false);
      const timer = setTimeout(() => {
        setFade(true);
        setTimeout(() => {
          setSuccess('');
          setError('');
        }, 500);
      }, 4500);

      return () => clearTimeout(timer);
    }
  }, [success, error]);
  const handleSignUp = async (event) => {
    event.preventDefault();
    setError('Interested? Contact On Discord To Become a Beta Tester');
  };
  /*const handleSignUp = async (event) => {
    event.preventDefault();

    if (password.length < 7) {
      setError('Password must be at least 7 characters long.');
      return;
    }

    try {
      const response = await fetch('api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Sign-up successful!');
        setUsername('');
        setEmail('');
        setPassword('');
      } else {
        setError(data.message || 'Sign-up failed!');
      }
    } catch (err) {
      setError('An error occurred during sign-up.');
    }
  };*/

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ usernameOrEmail, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Login successful!');
        setUsernameOrEmail('');
        setPassword('');
        navigate('/dashboard');  // Navigate to the Dashboard after successful login
      } else {
        setError(data.message || 'Login failed!');
      }
    } catch (err) {
      setError('An error occurred during login.');
    }
  };

  return (
    <div className="signup-login-modal">
      <div className="modal-content" ref={modalRef}>
        <button className="close-button" onClick={closeForm}>X</button>
        <div className="tab-buttons">
          <button 
            className={`tab ${activeTab === 'login' ? 'active' : ''}`} 
            onClick={() => handleTabClick('login')}
          >
            Login
          </button>
          <button 
            className={`tab ${activeTab === 'signup' ? 'active' : ''}`} 
            onClick={() => handleTabClick('signup')}
          >
            Sign Up
          </button>
        </div>
        <div className={`tab-content ${activeTab === 'signup' ? 'slide-in-left' : 'slide-in-right'}`}>
          {activeTab === 'signup' && (
            <div className="signup-form">
              <h2>Sign Up</h2>
              <form onSubmit={handleSignUp}>
                <div>
                  <label>Username:</label>
                  <input 
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                  />
                </div>
                <div>
                  <label>Password:</label>
                  <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                </div>
                <button type="submit" className="submit-button">Sign Up</button>
              </form>
              {error && <div className={`message-box error-message ${fade ? 'hidden' : ''}`}>{error}</div>}
              {success && <div className={`message-box success-message ${fade ? 'hidden' : ''}`}>{success}</div>}
              <div className="auth-buttons">
                <button className="auth-button steam">
                  <img src={steam} alt="Steam Icon" />
                  Sign Up with Steam
                </button>
                <button className="auth-button google">
                  <img src={google} alt="Google Icon" />
                  Sign Up with Google
                </button>
              </div>
            </div>
          )}
          {activeTab === 'login' && (
            <div className="login-form">
              <h2>Login</h2>
              <form onSubmit={handleLogin}>
                <div>
                  <label>Username or Email:</label>
                  <input 
                    type="text" 
                    value={usernameOrEmail} 
                    onChange={(e) => setUsernameOrEmail(e.target.value)} 
                    required 
                  />
                </div>
                <div>
                  <label>Password:</label>
                  <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                  />
                </div>
                <button type="submit" className="submit-button">Login</button>
              </form>
              {error && <div className={`message-box error-message ${fade ? 'hidden' : ''}`}>{error}</div>}
              {success && <div className={`message-box success-message ${fade ? 'hidden' : ''}`}>{success}</div>}
              <div className="auth-buttons">
                <button className="auth-button steam">
                  <img src={steam} alt="Steam Icon" />
                  Login with Steam
                </button>
                <button className="auth-button google">
                  <img src={google} alt="Google Icon" />
                  Login with Google
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupLoginSetup;
