// src/Homepagemodules/Offer.js
import React from 'react';
import './Offer.css';

function Offer() {
  return (
    <div className="offer-section">
      <h2 className="offer-heading">Our Unrivaled Provisions</h2>
      <div className="offer-container">
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Seamless Integration</h3>
          <p className="offer-feature-description">
            Integrate across multiple platforms effortlessly.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Advanced Analytics</h3>
          <p className="offer-feature-description">
            Gain deep insights with our advanced reporting tools.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Real-Time Notifications</h3>
          <p className="offer-feature-description">
            Stay informed with instant alerts and notifications.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Customizable Strategies</h3>
          <p className="offer-feature-description">
            Tailor your trading strategies to your needs.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Enhanced Security</h3>
          <p className="offer-feature-description">
            Protect your data with top-tier security features.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">24/7 Uptime</h3>
          <p className="offer-feature-description">
            Ensure your platform is always available with zero downtime.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Price Optimizations</h3>
          <p className="offer-feature-description">
            Ensure your skins are the first in queue for selling enabled by your customized percentages.
          </p>
        </div>
        <div className="offer-feature-box">
          <h3 className="offer-feature-title">Real-Time Market Monitoring</h3>
          <p className="offer-feature-description">
            Stay up to date on what is happening in the market.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Offer;
